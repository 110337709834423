import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError, pipe } from 'rxjs';

import { Token } from './classes/Token';
import { environment } from 'src/environments/environment';
import { NotificationServiceHM } from './menu/notification-item/notification-item.service'

@Injectable()

export class MainService {

     //public url = ' http://localhost:8080/api/v1';
     // public url_v2 = 'http://localhost:8080/api/v2'
    public url = environment.url_v1;
    public url_v1_v2 = environment.url_v1_v2;
    public url_v2 = environment.url_v2;

    public userToken = new Token();
    public workAreas: Array<any> = [ ];
    public httpOptions = {
        headers : new HttpHeaders ({
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Authorization': this.userToken.token || 'tokeninvalido'
            })
        };
    public sUsername: string = '';
    public sToken: string = '';
    public sRoles: Array<any> = [ ];
    public valid_login = false;
    public limite_login: number = 0;

    constructor( private _http: HttpClient,
        public notificationServiceHM: NotificationServiceHM) {
    }

    updateHeaders() {
        this.httpOptions = {
            headers : new HttpHeaders ({
                'Content-type': 'application/json',
                'Authorization': this.userToken.token
                })
            };
    }
    public validSession() {
        this.userToken.token = sessionStorage.getItem('Token') || '';
        this.userToken.role = sessionStorage.getItem('Role') || '';
        this.userToken.username = sessionStorage.getItem('User') || '';
        this.userToken.area = sessionStorage.getItem('Area') || '';
        this.userToken.areaID = sessionStorage.getItem('AreaID') || '';
        if ( sessionStorage.getItem('LimiteLogin') ){
            let limit = sessionStorage.getItem('LimiteLogin') || '0';
            this.limite_login = parseInt( limit )
        } else {
            this.limite_login = 0;
        }
        
         

        this.updateHeaders()
    }

    public logout() {
        sessionStorage.removeItem('Token');
        sessionStorage.removeItem('Role');
        sessionStorage.removeItem('User');
        sessionStorage.removeItem('Area');
        sessionStorage.removeItem('AreaID');
        location.reload();
    }

    // - LOGIN -----------------------------------------------------------------------------------------------------
    getUsers (): Observable<any> {
        return this._http.get('/api/v1/users', this.httpOptions )
                    .pipe(
                            retry(3),
                            map(users => {
                                return users;
                            }),
                            catchError(this.handleError));
    }

    createUser (username, password, role = 'user'): Observable<any> {
        return this._http.post<any>('/api/v1/users', JSON.stringify({username, password, role}), this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getToken (username, password): Observable<any> {
        this.valid_login = this.checkLimitiLogin();
        if (!this.valid_login) {
            return this._http.post<any>('/api/v1/tokens', JSON.stringify({username, password}), this.httpOptions)
            .pipe(
                map(res => {
                    this.userToken.token = res.token;
                    this.userToken.role = this.checkMainRole(res.roles);
                    this.sRoles = res.roles;
                    this.userToken.area = res.area;
                    this.userToken.areaID = res.areaID;
                    this.userToken.restricted = res.restricted;
                    this.updateHeaders();
                    sessionStorage.setItem('Token', this.userToken.token);
                    sessionStorage.setItem('Role', this.userToken.role);
                    sessionStorage.setItem('User', this.userToken.username.toLowerCase());
                    sessionStorage.setItem('Area', this.userToken.area);
                    sessionStorage.setItem('AreaID', this.userToken.areaID);
                    sessionStorage.setItem('Restricted', this.userToken.restricted);
                    return this.userToken.token;
            }),
                catchError(this.handleError)
        );
        } else {
            this.reportLimitAndCheckTimeout()
            return throwError(() => new Error('I have a bad feeling about this... 401 -> Limite de Logins Excedido1')) 
        }
        
    }

    getTokenV2 (username, password): Observable<any> {
        this.valid_login = this.checkLimitiLogin();
        if (!this.valid_login) {
            return this._http.post<any>('/api/v2/auth', JSON.stringify({username, password}), this.httpOptions)
            .pipe(
                map(res => {
                    return this.userToken.token;
            }),
                catchError(this.handleError)
        );
        } else {
            this.reportLimitAndCheckTimeout()
            return throwError(() => new Error('I have a bad feeling about this... 401 -> Limite de Logins Excedido2')) 
        }
        
    }

    checkLimitiLogin() {
        let valid = false
        if ( sessionStorage.getItem('LimiteLogin') ){
            let limit = sessionStorage.getItem('LimiteLogin') || '0';
            this.limite_login = parseInt( limit )
            if (this.limite_login >= 6 ){
                valid = true;
            }
            console.log('limite atual', this.limite_login)
        } else {
            this.limite_login = 0;
        }
        return valid;

    }

    reportLimitAndCheckTimeout() {
        if ( sessionStorage.getItem('LimiteLogin_timestamp') ){
            console.log('chegou aqui no limit')
            let timestamp = sessionStorage.getItem('LimiteLogin_timestamp') || '0';
            let time_before = parseInt( timestamp )
            let hour_now = new Date().getTime()
            let timePass = new Date(hour_now - time_before).getUTCMinutes()
            let timeLeft = 5-timePass
            if ( timeLeft <= 0) {
                sessionStorage.removeItem('LimiteLogin_timestamp');
                sessionStorage.removeItem('LimiteLogin');
                this.limite_login = 0;
                this.valid_login = false;
                this.notificationServiceHM.showToast('Tente novamente realizar o login', 'warning');
            } else {
                this.notificationServiceHM.showToast('Limite de logins excedido! Próxima tentativa em ' +  timeLeft + ' minutos', 'error')
            }

        }

    }

    getPATAToken (): Observable<any> {
        return this._http.get('/api/v1/tokens/pata', this.httpOptions )
                    .pipe(
                            retry(3),
                            map(token => {
                                return token;
                            }),
                            catchError(this.handleError));
    }

    getCompileToken(): Observable<any> {
        return this._http.get('/api/v1/compile/token', this.httpOptions )
                    .pipe(
                        retry(3),
                        map(token => {
                            let compileKey: any = token;
                
                            let tmp = new Date();
                            tmp.setTime(compileKey.payload.exp * 1000);
                            compileKey.payload.exp = `${tmp.toLocaleString()}`;

                            tmp = new Date();
                            tmp.setTime(compileKey.payload.iat * 1000);
                            compileKey.payload.iat = `${tmp.toLocaleString()}`;
                            
                            return token;
                        }),
                        catchError(this.handleError));
    }

    createPATAToken ( username, password ): Observable<any> {
        return this._http.post('/api/v1/tokens/pata', JSON.stringify({ username, password }), this.httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.handleError)
            );
    }

    updatePATAToken(id, compileToken, compileExpiration, compileAuth, compileSUB, compileISS): Observable<any> {
        this.updateHeaders();

        return this._http.put('/api/v1/tokens/pata/' + id, JSON.stringify({ compileToken, compileExpiration, compileAuth, compileSUB, compileISS}), this.httpOptions)
            .pipe(
                map( res => {
                    return res;
                }),
                    catchError(this.handleError)
            );
    }

    deletePATAToken(id): Observable<any> {
        return this._http.delete('/api/v1/tokens/pata/' + id, this.httpOptions)
        .pipe(
            catchError(this.handleError)
        );
    }

    getUserPerArea(): Observable<any> {
        this.updateHeaders();

        return this._http.get('/api/v1/users/' , this.httpOptions )
                .pipe(
                    retry(3),
                    map(res => {
                        return res;
                    }),
                    catchError(this.handleError));
    }

    getAreas(): Observable<any> {
        this.updateHeaders();

        return this._http.get('/api/v1/area', this.httpOptions )
                .pipe(
                    retry(3),
                    map(res => {
                        return res;
                    }),
                    catchError(this.handleError));
    }

    getAreaName(id: string) {
        let areaIndex;
        let areaName;
        areaIndex = this.workAreas.find(obj => obj.id === id);
        areaIndex = this.workAreas.indexOf(areaIndex);
        if ( areaIndex > -1 ) {
            areaName = this.workAreas[areaIndex].area;
        } else {
            areaName = '';
        }

        return areaName;
    }

    changeArea (username, area): Observable<any> {
        this.updateHeaders();

        return this._http.put('/api/v1/users', JSON.stringify({username, area}), this.httpOptions)
            .pipe(
                map( res => {
                    this.userToken.area = area;
                    sessionStorage.setItem('Area', this.userToken.area);
                    return res;
                }),
                    catchError(this.handleError)
            );
    }

    checkToken (): boolean {
        if (this.userToken.token !== null && this.userToken.token !== '' && this.userToken.token !== undefined ) {
            return true;
        } else {
            this.validSession();
            if (this.userToken.token !== null && this.userToken.token !== '' && this.userToken.token !== undefined ) {
                this.updateHeaders();
                return true;
            }
            console.error('YOU SHALL NOT PASS!');
            return false;
        }
    }

    checkArea (){
        if (this.userToken.area !== null && this.userToken.area !== '' && this.userToken.area !== undefined ) {
                return true;
        } else {
            return false
        }

    }

    checkMainRole(roles) {
        let mainRole: string = '';

        if (roles.includes('superuser')) {
            mainRole = 'superuser';
        } else {
            if (roles.includes('team')) {
                mainRole = 'team';
            } else {
                mainRole = 'user'
            }
        }
         return mainRole;
    }
    // - LOGIN --------------------------------------------------------------------------------------------------------

    // - ACTION ------------------------------------------------------------------------------------------
    getActionsbyName (login): Observable<any> {
        return this._http.get('/api/v1/actions?username=' + login, this.httpOptions )
                    .pipe(
                            retry(3),
                            map(actions => {
                                return actions;
                            }),
                            catchError(this.handleError));
    }

    getActionsbyType (type): Observable<any> {
        return this._http.get('/api/v1/actions?actionType=' + type, this.httpOptions )
                    .pipe(
                            retry(3),
                            map(actions => {
                                return actions;
                            }),
                            catchError(this.handleError));
    }

    getActionsbyResource (resourceId): Observable<any> {
        return this._http.get('/api/v1/actions?resourceId=' + resourceId, this.httpOptions )
                    .pipe(
                            retry(3),
                            map(actions => {
                                return actions;
                            }),
                            catchError(this.handleError));
    }

    createAction (name, expirationDays, recipeId): Observable<any> {
        return this._http.post('/api/v1/actions', JSON.stringify({ name, expirationDays, recipeId }), this.httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.handleError)
            );
    }

    createLoginAction (username, description, resourceId): Observable<any> {
        return this._http.post('/api/v1/loginActions', JSON.stringify({ username, description, resourceId }), this.httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.handleError)
            );
    }

    modifyLoginAction (id, username, recipeId): Observable<any> {
        this.updateHeaders();

        return this._http.put('/api/v1/loginActions/' + id, JSON.stringify({username, recipeId}), this.httpOptions)
            .pipe(
                map( res => {
                    return res;
                }),
                    catchError(this.handleError)
            );
    }

    modifyAction (id, name, expirationDays): Observable<any> {
        this.updateHeaders();

        return this._http.put('/api/v1/actions/' + id, JSON.stringify({name, expirationDays}), this.httpOptions)
            .pipe(
                map( res => {
                    return res;
                }),
                    catchError(this.handleError)
            );
    }

    deleteAction (id): Observable<any> {
        return this._http.delete('/api/v1/actions/' + id, this.httpOptions)
        .pipe(
            catchError(this.handleError)
        );
    }

    // - NOTIFICATION -------------------------------------------------------------------------------------------

    getNotifications(): Observable<any> {
        return this._http.get('/api/v1/notifications', this.httpOptions)
            .pipe(map( res => res), catchError(this.handleError));
    };

    readNotification(id): Observable<any> {
        return this._http.put('/api/v1/notifications/' + id, {seen: true}, this.httpOptions)
            .pipe(map(res => res), catchError(this.handleError));
    }

    handleError( error: HttpErrorResponse ) {
        if (error instanceof ErrorEvent) {
            console.error('Erro ocorrido: ', error);
        } else {
            console.error( 'O servidor te ignorou e respondeu ' + error.status + ' e ainda disse: ' + error.message  );            
        }
        return throwError(() => new Error('I have a bad feeling about this...' + error.status + ' and that ' + error.message)) 
    }

    ///--------------- PAINEL ADMINISTRATIVO ---------------------------------------------------------------------------

    getResumeAnalyzeDBs (): Observable<any> {
        return this._http.get('/api/v1/resources/AnalyzeDBs/resume', this.httpOptions )
                    .pipe(
                            retry(3),
                            map(resume => {
                                return resume;
                            }),
                            catchError(this.handleError));
    }

    runAnalyzeDBs (): Observable<any> {
        return this._http.get('/api/v1/resources/AnalyzeDBs/run', this.httpOptions )
                    .pipe(
                            retry(3),
                            map(resume => {
                                return resume;
                            }),
                            catchError(this.handleError));
    }

    /// ----------------------------------------------------------------------------------------------------------------

}
