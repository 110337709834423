<div class="po-row">
    <po-info p-label="ID" class="po-md-4" [p-value]="_id"></po-info>
</div>
<div class="po-row">
    <po-info p-label="Status" class="po-md-2" [p-value]="_deployStats" ></po-info>
    <po-info p-label="Status Detail" class="po-md-4" [p-value]="_deployStatsData_message" ></po-info>
</div>

<po-divider></po-divider>

<div class="po-row">
  <po-info p-label="Database ID" class="po-md-4" [p-value]="_sgdbData_id"></po-info>
  <po-info p-label="Type" class="po-md-4" [p-value]="_sgdbData_type" ></po-info>
</div>
<div class="po-row">
  <po-info p-label="Username" class="po-md-4" [p-value]="_sgdbData_username" ></po-info>
  <po-info p-label="Password" class="po-md-4" [p-value]="_sgdbData_password" ></po-info>
</div>

<po-divider></po-divider>

<div class="po-row" *ngFor="let artifact of _artifacts; let i = index">
  <po-info [p-label]="artifact.kind" class="po-md-4" p-orientation="horizontal" [p-value]="artifact.name" ></po-info>
  <po-info p-label="IP" class="po-md-4" p-orientation="horizontal" [p-value]="artifact.ip" ></po-info>
  <po-info p-label="Porta" class="po-md-2" p-orientation="horizontal" [p-value]="artifact.port" ></po-info>
</div>
<div class="po-row">
  <po-info p-label="Acessar o WebApp" class="po-md-2" p-orientation="horizontal" [p-value]="_webappURL" [p-url]="_webappURL" ></po-info>
</div>

<po-divider></po-divider>

<div class="po-row">
  <po-textarea class="po-sm-12" 
    name="ini" 
    [(ngModel)]="_ini" 
    p-label="INI de criação" 
    p-readonly="true"
    p-rows="15">
  </po-textarea>
</div>