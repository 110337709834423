import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../../app.service';
import { ProtheusService } from './protheus.service';
import { Binarios } from '../../classes/Binarios';
import { Localizacoes } from '../../classes/Localizacoes';
import { Releases } from '../../classes/Releases';
import { Rpo } from '../../classes/Rpo';
import { Protheus } from '../../classes/Protheus';
import { Router } from '@angular/router';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { ForceOptionComponentEnum, PoDynamicFormField, PoListViewAction, PoModalAction, PoModalComponent, PoNotification, PoNotificationService, PoSwitchLabelPosition, PoTagType } from '@po-ui/ng-components';
import { first, Subject, takeUntil, Observable } from 'rxjs';
import { IniParameter, ProtheusINI } from 'src/app/classes/ProtheusINI';
import { Bases } from 'src/app/classes/Bases';
interface objectForm {
  label: string,
  property: string,
  required: boolean,
  minLength?: number,
  maxLength?: number,
  gridColumns: number,
  gridSmColumns: number,
  fieldValue: any,
  disabled: boolean,
  divider?: string,
  value?: string
}

interface tempIniGroup {
  group?: string,
  keys?: object[]
}

@Component({
  selector: 'app-protheus',
  templateUrl: './protheus.component.html',
  styleUrls: ['./protheus.component.css']
})



export class ProtheusComponent implements OnInit {

  @ViewChild('detailsModal', {static: false}) protheusDetailsModal: PoModalComponent;
  @ViewChild('createModal', {static: false}) createProtheusModal: PoModalComponent;
  @ViewChild('updateModal', {static: false}) protheusUpdateModal: PoModalComponent;
  @ViewChild('deleteModal', {static: false}) deleteProtheusModal: PoModalComponent;
  @ViewChild('shareModal', {static: false}) shareModal: PoModalComponent;
  @ViewChild('editINIModal', {static: false}) editINIProtheusModal: PoModalComponent;
  @ViewChild('insertFieldModal', {static: false}) insertFieldModal: PoModalComponent;
  

  constructor(private router: Router,
    public mainService: MainService,
    private protheusService: ProtheusService,
    public poNotificationService: PoNotificationService,
    private readonly tracker: MatomoTracker) { }

  loading: boolean = false;
  unsubscribe$ = new Subject<void>();
  protheus: any[] = [];
  protheusFiltered: any[] = [];

  binarios: any[] = [];
  binariosSelect: any[] = [];

  dbaccess: any[] = [];
  dbaccessSelect: any[] = [];

  localizacoes: any[] = [];
  localizacoesSelect: any[] = [];
  idiomas: any[] = [];
  disableLocalizacao: boolean = true;

  rpos: any[] = [];
  rposSelect: any[] = [];
  disableRPO: boolean = true;

  bases: any[] = [];
  baseSelect: any[] = [];

  releases: any[] = [];
  releasesSelect: any[] = []

  _protheusSelected: Object = {};
  _id: string = '';
  _name: string = '';
  _ini: string = '';
  _release: string = '';
  _localizacao: string = '';
  _idioma: string = '';
  _appserver: string = '';
  _appserverId: string = '';
  _dbaccess: string = '';
  _rpo: string = '';
  _rest: boolean = false;
  _mpp: boolean = false;
  _sgdbType: any = undefined;
  _sgdbKind: any = undefined;
  _baseName: string = '';
  _expirationDays: number = 7;
  _shared: string[] = [''];
  _iniToEdit: Array<any> = [ ];
  _fullINIToEdit: Array<any> = [ ];
  objetosIni: Array<any> = [ ];
  objetoSelecionado: any = {};
  iniObject: any = {};
  previousIni: any = {};
  iniInstance: string = '';
  iniType: string = '';
  _configsToEditIni = {};
  iniToEditFields: Array<PoDynamicFormField> = [ ];
  iniToEditValues: any = {};
  iniToEditGroups: Array<any> = [];
  newGroup: boolean = false;
  insertField: boolean = true;
  deleteField: boolean = false;
  insertDeleteField: boolean = true;
  fieldsToSelect: Array<any> = [];
  fieldsToDelete: Array<any> = [];
  groupINI: string = '';
  _newGroup: string = '';
  newFieldsINI: Array<PoDynamicFormField> = [ ];
  newValuesINI: Record<string, any> = {};
  iniToEditValuesSaved = {};
  INIformVisible = false;
  status_Processing = [
                      'creating database', 
                      'creating protheus', 
                      'restarting protheus', 
                      'starting protheus', 
                      'stopping protheus', 
                      'editing protheus ini', 
                      'reseting protheus ini' ]

  expiraOptions: Array<any> = [
    { label: '20', value: 20 },
    { label: '40', value: 40 }
  ];

  message: string = '';
  poNotification: PoNotification = {
    message: this.message,
    action: undefined
  };

  labelPosition: PoSwitchLabelPosition = PoSwitchLabelPosition.Left;

  filterInstancesModel: Object = {
    active: true,
    deleted: false,
    expired: false,
    failed: false
  }

  filterOptionsSelect: Array<any> = []
  filterSelected: string = '';
  ascendingDescending: boolean = true;

  eventsObject: Array<PoListViewAction> = [
    { label: 'Detalhes', action: this.detalhesProtheus.bind(this) },
    { label: 'Renomear/Prorrogar', action: this.updateProtheus.bind(this), disabled: this.cannotRename.bind(this) },
    { label: 'Editar INI', action: this.getOriginalIniToModifiy.bind(this), disabled: this.cannotEditINI.bind(this) },
    { label: 'Reiniciar', action: this.restartProtheus.bind(this), disabled: this.cannotRestart.bind(this)},
    { label: 'Compartilhar', action: this.shareResource.bind(this), disabled: this.cannotShare.bind(this) },
    { label: 'Deletar', action: this.deleteProtheus.bind(this), disabled: this.cannotDelete.bind(this) }
  ];

  createProtheusValues: Object = {};
  createProtheusFields: Array<PoDynamicFormField> = []
  createProtheusValidationFields: Array<String> = [ '_release', '_idioma', '_localizacao', '_appserver', '_dbaccess', '_sgdbType']
  
  createProtheusValidation(received) {
    console.log(received)
  }

  createProtheusExecute: PoModalAction = {
    action: () => {
      let observable: Observable<{kind?: string}>;

      if (this._sgdbKind === 'template') {
        observable = this.protheusService.createProtheus2(this._name, this._release, this._appserverId, this._dbaccess, this._localizacao, this._rpo, this._rest, this._mpp, this._sgdbType, this._expirationDays);
      } else {
        observable = this.protheusService.createProtheus(this._name, this._release, this._appserverId, this._dbaccess, this._localizacao, this._rpo, this._rest, this._mpp, this._sgdbType, this._expirationDays)
      }

      observable.subscribe((res) => {
        this.poNotification.message = "Base Protheus em processo de criação";
        this.poNotificationService.success(this.poNotification);
      });

      this.createProtheusModal.close();
      this.getAllProtheus();
    },
    label: 'Criar'
  };

  deleteApprove = '';

  deleteProtheusExecute: PoModalAction = {
    action: () => {
      if (this.deleteApprove === 'SIM') {

        this.protheusService.deleteProtheus (this._id)
        .subscribe(
          res => {
            this.poNotification.message = "Base Protheus em processo de remoção";
            this.poNotificationService.success(this.poNotification);
          }
        );
        this.deleteProtheusModal.close();
        this.getAllProtheus(); 
        this.resetFilters();

      } else {
        this.poNotification.message = "Você deve aprovar a deleção primeiro!";
        this.poNotificationService.error(this.poNotification);
      }
      
    },
    label: 'Deletar'
  };

  getUpdateData(event: { _name: string, _expirationDays: number}){
    this._name = event._name;
    this._expirationDays = event._expirationDays
  }

  updateProtheusExecute: PoModalAction = {
    action: () => {
      this.protheusService.updateProtheus(this._id, this._name, this._expirationDays)
      .subscribe(
        res => {
          this.poNotification.message = "Registro atualizado com sucesso!";
          this.poNotificationService.success(this.poNotification);
        }
      );
      this.protheusUpdateModal.close();
      this.getAllProtheus(); 
      this.resetFilters();
    },
    label: 'Criar'
  };

  editINIProtheusExecute: PoModalAction = {
    action: () => {
      this.convertValuesToINI();
    },
    label: 'Salvar'
  };

  editINIProtheusCancel: PoModalAction = {
    action: () => {
      this.resetIniValues();
    },
    label: 'Cancelar'
  };

    // - SHARE - //
    protheusIdToShare: string = '';
    usersInArea: Array<any> = [];
    usersSelected: Array<any> = [];
  
    getUpdateSharedData(event: { usersSelected: Array<any>}){
      this.usersSelected = event.usersSelected;
    }
    okShare: PoModalAction = {
      action: () => {
          this.shareInstance();
          this.loading = false;
          this.shareModal.close();
      },
      label: 'Compartilhar'
    };
  
    cancelShare: PoModalAction = {
      action: () => {
        this.loading = false;
        this.shareModal.close();
      },
      label: "Cancelar"
    };

  ngOnInit() {
    this.tracker.trackPageView('Protheus');
    this.getAllReleases();
    this.loading = false;
    this.filterOptionsSelect = [
      { label: "Nome", value: "name"  },
      { label: "Status", value: "deployStatsDataResume"  },
      { label: "Data de Criação", value: "createdAt"  },
      { label: "Expiração", value: "remainingTime"  },
    ]
  }

  getAllProtheus(){
    this.loading = true
    this.protheus = [ ];
    this.protheusFiltered = [];
    this.protheusService.getProtheus()
      .subscribe({
        next: ((response) => {
          response.forEach(element => {
            const protheusTemp = new Protheus();
            protheusTemp.id = element.id;
            protheusTemp.name = element.name;
            protheusTemp.username = element.username;
            protheusTemp.deployStats = element.deployStats;
            protheusTemp.deployStatsData = element.deployStatsData;

            if ( element.deployStatsData !== undefined && element.deployStatsData.hasOwnProperty('message') ) {
              protheusTemp.deployStatsDataResume = element.deployStats + ' - ' + element.deployStatsData['message'];
            } else {
              protheusTemp.deployStatsDataResume = element.deployStats
            }

            if (this.status_Processing.includes(element.deployStats.toLowerCase())) {
              protheusTemp.statusType = PoTagType.Warning;
              protheusTemp.active = true;
            } else if (element.deployStats === 'expiring') {
              protheusTemp.statusType = PoTagType.Warning;
              protheusTemp.active = true;
            } else if (element.deployStats === 'expired') {
              protheusTemp.statusType = PoTagType.Danger;
              protheusTemp.active = true;
            } else if (element.deployStats === 'deleted' || element.deployStats === 'failed') {
              protheusTemp.statusType = PoTagType.Info;
              protheusTemp.active = false;
            } else {
              protheusTemp.statusType = PoTagType.Success;
              protheusTemp.active = true;
            }

            protheusTemp.sgdbData = element.sgdbData;
            protheusTemp.artifacts = element.artifacts;

            let artifactIndex;
            artifactIndex = protheusTemp.artifacts.find(obj => obj.name.toString() === 'webapp');
            if (artifactIndex) {
              if (element.specs.appserver[0].modifiedIni !== undefined ){
               
                if (element.specs.appserver[0].modifiedIni.drivers['multiprotocolport'] !== undefined && element.specs.appserver[0].modifiedIni.drivers['multiprotocolport'] === '1' && element.specs.appserver[0].modifiedIni.general['app_environment'] !== '') {
                  let artifactPortIndex = protheusTemp.artifacts.find(obj => obj.name.toString() === 'smartclient');
                  let urlTemp = ''
                  if (artifactPortIndex) {
                    urlTemp = 'http://' + artifactIndex.ip.toString() + ':' + artifactPortIndex.port.toString() + '/webapp';
                  } else {
                    urlTemp = 'http://' + artifactIndex.ip.toString() + ':' + 'porta não encontrada - usar porta do smartclient';
                  }
                  
                  artifactIndex = protheusTemp.artifacts.indexOf(artifactIndex);
                  protheusTemp.artifacts[artifactIndex].url = urlTemp
                } else {
                  let urlTemp = 'http://' + artifactIndex.ip.toString() + ':' +  artifactIndex.port.toString()
                  artifactIndex = protheusTemp.artifacts.indexOf(artifactIndex);
                  protheusTemp.artifacts[artifactIndex].url = urlTemp
                }
              }              
            };

            let artifactFBIndex;
            artifactFBIndex = protheusTemp.artifacts.find(obj => obj.name.toString().toLowerCase() === 'filebrowser');
            if (artifactFBIndex) {
              let urlTemp = 'http://' + artifactFBIndex.ip.toString() + ':' +  artifactFBIndex.port.toString()
              let nameTemp = artifactFBIndex.name.toLowerCase();
              artifactFBIndex = protheusTemp.artifacts.indexOf(artifactFBIndex);
              protheusTemp.artifacts[artifactFBIndex].url = urlTemp
              protheusTemp.artifacts[artifactFBIndex].name = nameTemp;
            }
            
            protheusTemp.sgdbDataResume = 'Login: ' + element.sgdbData.username + ' - Senha: ' + element.sgdbData.password;
            const resumeName = this.getReleaseName(element.specs.release.releaseId);
            protheusTemp.releaseLocResume = resumeName;
            if (element.expirationDate) {
              const today = new Date();
              const expirationDate = new Date(element.expirationDate.split('T', 1)[0]);
              protheusTemp.remainingTime = Math.floor((Math.abs(today.valueOf() - expirationDate.valueOf()))/(1000*60*60*24));
            } else {
              protheusTemp.remainingTime = 0;
            }
            protheusTemp.createdAt = element.createdAt.split('T', 1);
            protheusTemp.updatedAt = element.updatedAt.split('T', 1);
            protheusTemp.shared = element.shared;
            
            this.protheus = [...this.protheus, protheusTemp];
            if (element.deployStats === 'creating database' 
                || element.deployStats === 'creating protheus' 
                || element.deployStats === 'active'
                || element.deployStats === 'deployed'
                || element.deployStats === 'expiring'
                || element.deployStats === 'restarting protheus'
                || element.deployStats === 'starting protheus'
                || element.deployStats === 'stopping protheus'
                || element.deployStats === 'editing protheus ini'
                || element.deployStats === 'reseting protheus ini') {
                  this.protheusFiltered = [...this.protheusFiltered, protheusTemp];
                }

          });
          this.loading = false;
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
  }

  getProtheusbyID(protheusId){
    this.loading = true;
    this.protheusService.getProtheusbyID(protheusId)
      .subscribe({
          next: ((element) => {
            console.log('recebeu do backend', element, this._protheusSelected)
                this._id = protheusId;
                this._name = element.name;
                this._protheusSelected = element;
                this.loading = false;
            }),
            error: ((msgError) => {
              this.mainService.handleError(msgError);
              this.loading = false;
            })
        });
  }

  getAllReleases() {
    this.loading = true
    this.releases = [ ];
    this.protheusService.getReleases()
      .subscribe({
          next: ((response) => {
            response.forEach(element => {
                const releaseTemp = new Releases();
                releaseTemp.id = element._id;
                releaseTemp.name = element.name;
                releaseTemp.idioma = element.idiomas;
                releaseTemp.valid = element.valid;

                this.releases = [...this.releases, releaseTemp];

                if (this.mainService.userToken.role === 'superuser') {
                  this.releasesSelect = [...this.releasesSelect, { label: element.name, value: element._id }];
                } else {
                  if ( element.valid ) {
                    this.releasesSelect = [...this.releasesSelect, { label: element.name, value: element._id }];
                  }
                }
                
              });
              this.getAllProtheus();
              this.loading = false;
            }),
            error: ((msgError) => {
              this.mainService.handleError(msgError);
              this.loading = false;
            })
        });

  }

  onChangeRelease(releaseSelected) {
    this.loading = true;

    this._localizacao = '';
    this._idioma = '';
    this._appserver = '';
    this._appserverId = '';
    this._dbaccess = '';
    this._rpo = '';
    this.idiomas = [];
    this._rest = false;
    this._sgdbType = undefined;
    this.localizacoes = [];
    this.localizacoesSelect = [];
    this.binarios = [];
    this.dbaccess = [];
    this.binariosSelect = [];
    this.dbaccessSelect = [];

    this.getLocalizacoes(releaseSelected);
    this.getBinarios(releaseSelected);
    this.disableLocalizacao = false;

    this.loading = true;

    let releaseIndex;
    releaseIndex = this.releases.find(obj => obj.id === releaseSelected);
    releaseIndex = this.releases.indexOf(releaseIndex);

    if ( releaseIndex > -1 ) {
        this.releases[releaseIndex].idioma.forEach( element => {
          this.idiomas = [...this.idiomas, { label: element, value: element }];
        });
    } else {
      this.idiomas = [{label: 'NOT FOUND', value: 'NOT FOUND'}];
    }

    this.loading = false;

  }

  getLocalizacoes(releaseId) {
    this.localizacoes = [ ];
    this.loading = true;
    this.protheusService.getLocalizacoes(releaseId)
      .subscribe({
        next: ((response) => {
          response.forEach(element => {
                const protheusTemp = new Localizacoes();
                protheusTemp.id = element._id;
                protheusTemp.release = element.release;
                protheusTemp.localizacao = element.locale;
                protheusTemp.link_menu = element.link_menu;
                protheusTemp.link_help = element.link_help;
                protheusTemp.link_dic = element.link_dic;
                protheusTemp.published = element.published;

                if (this.mainService.userToken.role === 'superuser') {
                  this.localizacoes = [...this.localizacoes, protheusTemp];
                  this.localizacoesSelect = [...this.localizacoesSelect, { label: element.locale, value: element._id }];
                } else {
                  if ( protheusTemp.published ) {
                    this.localizacoes = [...this.localizacoes, protheusTemp];
                    this.localizacoesSelect = [...this.localizacoesSelect, { label: element.locale, value: element._id }];
                  }
                }
                
              });
              this.loading = false;
            }),
            error: ((msgError) => {
              this.mainService.handleError(msgError);
              this.loading = false;
            })
        });
  }

  onChangeLocalizacao(idiomaSelected) {
    
    this.loading = true;
    this.baseSelect = [];
    this.rpos = [];
    this.rposSelect = [];
    
    this.getRPO(this._release, this._localizacao, this._idioma);
    this.getBases(this._release, this._localizacao)
    this.disableRPO = false;
    
  }


  onChangeBinary(binarySelected) {
    this.dbaccessSelect = [];
    this.getDbAccess(this._release, binarySelected) 
  }

  onChangeDbaccess(dbaccessSelected) {
    this.getBinariosFilter(this._release, this._appserver, dbaccessSelected) 
  }

  getBinarios(releaseId) {
    this.binarios = [ ];
    this.loading = true;
    this.protheusService.getBinarios(releaseId)
      .subscribe({
        next: ((response) => {
          response.forEach(element => {
                const protheusTemp = new Binarios();
                protheusTemp.id = element._id;
                protheusTemp.release = element.release;
                protheusTemp.appserver_version = element.appserver_version;
                protheusTemp.appserver_image = element.appserver_image;
                protheusTemp.dbaccess_version = element.dbaccess_version;
                protheusTemp.dbaccess_image = element.dbaccess_image;
                protheusTemp.published = element.published;
                
                if (this.mainService.userToken.role === 'superuser') {
                  this.binarios = [...this.binarios, protheusTemp];
                  this.binariosSelect = [...this.binariosSelect, { label: element.appserver_version, value: element.appserver_version }];
                } else {
                  if ( protheusTemp.published ) {
                    this.binarios = [...this.binarios, protheusTemp];
                    this.binariosSelect = [...this.binariosSelect, { label: element.appserver_version, value: element.appserver_version }];
                  }
                }
              })
            }),
              error: ((msgError) => {
                this.mainService.handleError(msgError);
                this.loading = false;
              })
          });
  }

  getBinariosFilter(releaseId, appserver, dbaccess) {
    this.protheusService.getBinariosFilter(releaseId, appserver, dbaccess)
      .subscribe({
        next: ((response) => {
          response.forEach(element => {
                const protheusTemp = new Binarios();
                protheusTemp.id = element._id;
                protheusTemp.release = element.release;
                protheusTemp.appserver_version = element.appserver_version;
                protheusTemp.appserver_image = element.appserver_image;
                protheusTemp.dbaccess_version = element.dbaccess_version;
                protheusTemp.dbaccess_image = element.dbaccess_image;
                protheusTemp.published = element.published;
                
                this._appserverId = element._id;

              });
            }),
            error: ((msgError) => {
              this.mainService.handleError(msgError);
              this.loading = false;
            })
        });
  }

  getDbAccess(releaseId, binary_version) {
    this.loading = true;
    this.dbaccess = [ ];
    this.protheusService.getDbAccess(releaseId, binary_version)
      .subscribe({
        next: ((response) => {
          response.forEach(element => {
                  this.dbaccessSelect = [...this.dbaccessSelect, { label: element, value: element }];
              });
              this.loading = false;
            }),
            error: ((msgError) => {
              this.mainService.handleError(msgError);
              this.loading = false;
            })
        });

  }

  getRPO(releaseId, localizacaoId, idioma) {
    this.rpos = [ ];
    this.loading = true;
    this.protheusService.getRPOs(releaseId, localizacaoId)
      .subscribe({
        next: ((response) => {
          response.forEach(element => {
                const protheusTemp = new Rpo();
                protheusTemp.id = element._id;
                protheusTemp.release = element.release;
                protheusTemp.version = element.version;
                protheusTemp.idioma = element.idioma;
                protheusTemp.localizacao = element.localizacao;
                protheusTemp.link_rpo = element.link_rpo;
                protheusTemp.published = element.published;
                if (this.mainService.userToken.role === 'superuser') {
                  this.rpos = [...this.rpos, protheusTemp];
                  if (element.idioma === idioma) {
                    this.rposSelect = [...this.rposSelect, { label: element.version + ' - ' + element.idioma, value: element._id }];
                  }
                } else {
                  if ( protheusTemp.published ) {
                    this.rpos = [...this.rpos, protheusTemp];
                    if (element.idioma === idioma) {
                      this.rposSelect = [...this.rposSelect, { label: element.version + ' - ' + element.idioma, value: element._id }];
                    }
                  }
                }
                
              });
              this.loading = false;
            }),
            error: ((msgError) => {
              this.mainService.handleError(msgError);
              this.loading = false;
            })
        });

  }

  getBases(releaseId, localizacaoId) {
    this.loading = true;
    this.protheusService.getBases(releaseId, localizacaoId)
      .subscribe({
        next: ((response) => {
          response.forEach(element => {
            const protheusTemp = new Bases();
            protheusTemp.id = element._id;
            protheusTemp.release = element.release;
            protheusTemp.localizacao = element.localizacao;
            protheusTemp.tipo = element.tipo;
            protheusTemp.dbAlias = element.dbAlias;
            protheusTemp.recipeId = element.recipeId;
            protheusTemp.kind = element.kind;
            protheusTemp.published = element.published;

            let label = element.kind === 'template' ? element.name : `${element.dbAlias} - ${element.tipo}`;
            
            if (this.mainService.userToken.role === 'superuser') {
              this.bases = [...this.bases, protheusTemp];
              this.baseSelect = [...this.baseSelect, { label: label, value: element._id }];
            } else {
              if ( protheusTemp.published === true ) {
                this.bases = [...this.bases, protheusTemp];
                this.baseSelect = [...this.baseSelect, { label: label, value: element._id }];
              }
            }
          });
              this.loading = false;
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });

  }

  filterBaseItem(id: string) {
    let filtered = this.bases.filter(x => x.id == id);
    if (filtered.length < 1) return 'NOT FOUND';
    else return filtered[0];
  }

  onChangeBase(base) {
    let baseSelecionada = this.filterBaseItem(base)
    this._sgdbKind = baseSelecionada.kind;
  }

  filterInstances(filterObject){

    let filtered = Object.keys(filterObject).filter(key => filterObject[key] === true);
    if (filtered.includes('active')) filtered.push('creating database');
    if (filtered.includes('active')) filtered.push('creating protheus');
    if (filtered.includes('active')) filtered.push('restarting protheus');
    if (filtered.includes('active')) filtered.push('deployed');
    if (filtered.includes('active')) filtered.push('expiring');
    if (filtered.includes('active')) filtered.push('expired');
    
    if (filtered.includes('deleted')) filtered.push('deleting');
    if (filtered.includes('deleted')) filtered.push('expired');
    if (filtered.includes('deleted')) filtered.push('deleting protheus');
    if (filtered.includes('deleted')) filtered.push('deleting database');

    this.protheusFiltered = this.protheus.filter(x => filtered.includes(x.deployStats));
    this.onChangeFilterSelect(this.filterSelected);
  }

  toggleFilters() {
    document.querySelector('.filterProtheusChkGroup')!["hidden"] = !document.querySelector('.filterProtheusChkGroup')!["hidden"] || false;
    document.querySelector('.filterInstancesSelect')!["hidden"] = !document.querySelector('.filterInstancesSelect')!["hidden"] || false;
    
    if (document.querySelector('.filterInstancesInvertButton')!["style"]["cssText"] === "display: none;") {
      document.querySelector('.filterInstancesInvertButton')!["style"]["cssText"] = "display: block;"
    } else {
      document.querySelector('.filterInstancesInvertButton')!["style"]["cssText"] = "display: none;"
    }
  }

  resetFilters() {
    this.filterInstancesModel = {
      active: true,
      deleted: false,
      expired: false,
      failed: false
    }
  }

  onChangeFilterSelect(filter) {
    this.filterSelected = filter
    this.protheusFiltered.sort((a, b) => {
      if (a[filter] < b[filter]) {
        return this.ascendingDescending ? -1 : 1;
      }
      if (a[filter] > b[filter]) {
        return this.ascendingDescending ? 1 : -1;
      }
      return 0;
    }); 
  }

  invertFilterOrder(){
    this.ascendingDescending = !this.ascendingDescending;
    this.onChangeFilterSelect(this.filterSelected);
  }

  createProtheus() {
    this._name = '';
    this._release = '';
    this._localizacao = '';
    this._idioma = '';
    this._appserver = '';
    this._appserverId = '';
    this._dbaccess = '';
    this._rpo = '';
    this.idiomas = [];
    this._rest = false;
    this._sgdbType = undefined;

    this.disableLocalizacao = true;
    this.disableRPO = true;
    this.createProtheusFields = [
      { property: '_name', label: 'Nome', required: true, type: 'string', gridColumns: 12, gridSmColumns: 12 },

      { property: '_release', label: 'Release', required: true, options: this.releasesSelect, fieldLabel: 'label', fieldValue: 'value', gridColumns: 6, gridSmColumns: 12, forceOptionsComponentType: ForceOptionComponentEnum.select},

      { property: '_idioma', label: 'Idioma', required: true, options: this.idiomas, fieldLabel: 'label', fieldValue: 'value', gridColumns: 6, gridSmColumns: 12, forceOptionsComponentType: ForceOptionComponentEnum.select },
      
      { property: '_localizacao', label: 'Localização', required: true, options: this.localizacoesSelect, fieldLabel: 'label', fieldValue: 'value', gridColumns: 6, gridSmColumns: 12, divider: '', forceOptionsComponentType: ForceOptionComponentEnum.select},
      
      { property: '_appserver', label: 'Appserver', required: true, options: this.binariosSelect, fieldLabel: 'label', fieldValue: 'value', gridColumns: 6, gridSmColumns: 12, forceOptionsComponentType: ForceOptionComponentEnum.select },
      
      { property: '_rest', label: 'Habilitar porta REST?', required: true, type: 'boolean', gridColumns: 3, gridSmColumns: 6 },
      
      { property: '_mpp', label: 'Habilitar MultiProtocol Port?', required: true, type: 'boolean', gridColumns: 3, gridSmColumns: 6 },
      
      { property: '_dbaccess', label: 'DbAccess', required: true, options: this.dbaccessSelect, fieldLabel: 'label', fieldValue: 'value', gridColumns: 6, gridSmColumns: 12, forceOptionsComponentType: ForceOptionComponentEnum.select },
      
      { property: '_sgdbType', label: 'Database', required: true, options: this.baseSelect, fieldLabel: 'label', fieldValue: 'value', gridColumns: 6, gridSmColumns: 12, forceOptionsComponentType: ForceOptionComponentEnum.select },
      
      { property: '_rpo', label: 'RPO', required: true, options: this.rposSelect, fieldLabel: 'label', fieldValue: 'value', gridColumns: 6, gridSmColumns: 12, forceOptionsComponentType: ForceOptionComponentEnum.select },
    ];
    this.createProtheusModal.open();
  }

  detalhesProtheus(protheusSelected) {
    this._id = '';
    this._name = '';
    this.getProtheusbyID(protheusSelected['id']);
    this.protheusDetailsModal.open();
  }

  restartProtheus(protheusSelected) {
    this.protheusService.restartProtheus(protheusSelected['id'])
      .subscribe({
        next: ((response) => {
              this.poNotification.message = 'Base' + response.name + 'sendo reiniciada!';
              this.poNotificationService.success(this.poNotification);
              this.getAllProtheus();
            }),
            error: ((msgError) => {
              this.mainService.handleError(msgError);
              this.loading = false;
              this.poNotification.message = 'Erro ao reiniciar a base!';
              this.poNotificationService.error(this.poNotification);
            })
        });
  }

  updateProtheus(protheusSelected) {
    this._id = protheusSelected['id'];
    this._name = protheusSelected['name']
    this.protheusUpdateModal.open();
  }
              
  cannotRename(protheusSelected): boolean {
    return this.status_Processing.includes(protheusSelected.deployStats.toLowerCase());
  }

  cannotShare(protheusSelected): boolean {
    return protheusSelected.username !== this.mainService.userToken.username;
  }

  cannotRestart(protheusSelected): boolean {
    return ['creating database', 'creating protheus', 'deleting database', 'deleting protheus', 'failed', 'deleted', 'expired'].includes(protheusSelected.deployStats.toLowerCase());
  }

  cannotEditINI(protheusSelected): boolean {
    return this.status_Processing.includes(protheusSelected.deployStats.toLowerCase());
  }

  cannotDelete(protheusSelected): boolean {
    return this.status_Processing.includes(protheusSelected.deployStats.toLowerCase());
  }

  shareResource(instanciaSelect) {
    this.usersInArea = [];
    this.protheusIdToShare = instanciaSelect['id'];
    this.mainService.getUserPerArea()
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((response) => {
            response.forEach(element => {
              this.usersInArea = [...this.usersInArea, {value: element.username, label: element.username}];
            });
            this.usersSelected = instanciaSelect['shared'];
            this.loading = false;
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });  
    this.shareModal.open();
  }

  shareInstance() {
    this.protheusService.shareProtheus(this.protheusIdToShare, this.usersSelected)
      .subscribe(res => {
        this.loading = false;
      });
      this.getAllProtheus();
  }

  deleteProtheus(protheusSelected) {
    this._id = protheusSelected['id'];
    this.deleteProtheusModal.open();
  }

  // notification(message: string, notificationType: string) {
  //   this.poNotification.message = message;
  //   this.poNotificationService[notificationType](this.poNotification);
  // }

  getReleaseName(releaseId) {
    let releaseIndex = -1;
    releaseIndex = this.releases.find(obj => obj.id === releaseId);
    releaseIndex = this.releases.indexOf(releaseIndex);

    return this.releases[releaseIndex].name
  }

  getOriginalIniToModifiy(protheusSelected) {
    this.objetosIni = [];
    this.loading = true;
    this.INIformVisible = false;
    this._ini = protheusSelected['id'];
    this.protheusService.getINItoEdit(protheusSelected['id'])
      .subscribe({
        next: ((response) => {
          this._fullINIToEdit = response;
            response.forEach(element => {
              this.objetosIni = [...this.objetosIni, { label: element.instance + ' - ' + element.type, value: element }]
            });
            this.loading = false;
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
            this.poNotification.message = "Erro ao obter o nome da base!";
            this.poNotificationService.error(this.poNotification);
          })
        });

    this.iniToEditFields = [];
    this.iniToEditValues = {};
    this.iniToEditGroups = [];
    this.fieldsToSelect = [];
    this.editINIProtheusModal.open();
  }

  onChangeobjetosIni(iniSelected) {
    if ( iniSelected !== undefined ){
      this.iniToEditFields = [];
      this.iniToEditValues = {};
      this.iniToEditGroups = [];
      this.fieldsToSelect = [];
      this.previousIni = JSON.parse(JSON.stringify(iniSelected));
      this.extractInfoFromINI(iniSelected);
    }
  }

  resetFormValues() {
    this.iniToEditFields = [];
    this.iniToEditValues = {};
    this.iniToEditGroups = [];
    this.fieldsToSelect = [];
    this.extractInfoFromINI(this.iniObject);
  }

  resetFormValuesFromIni(){
    this.iniToEditFields = [];
    this.iniToEditValues = {};
    this.iniToEditGroups = [];
    this.fieldsToSelect = [];
    this.extractInfoFromINI(this.previousIni);
  }

  resetIniValues() {
    this.objetosIni = [];
    this.INIformVisible = false;
    this.iniToEditFields = [];
    this.iniToEditValues = {};
    this.iniToEditGroups = [];
    this.fieldsToSelect = [];
    this.iniObject = '';
    this.iniInstance = '';
    this.iniType = '';
    this._iniToEdit = [];
    this.objetoSelecionado = {};
    this.editINIProtheusModal.close()
  }

  extractInfoFromINI(iniObject) {

    this.iniObject = iniObject;
    this.iniInstance = iniObject.instance;
    this.iniType = iniObject.type;
    this._iniToEdit = iniObject.ini;

    this._iniToEdit.forEach(iniElement => {
      let countDivider = 0;
      let iniElementTemp = new IniParameter();
      iniElementTemp.group = iniElement.group;
      iniElementTemp.values = iniElement.keys;
      iniElementTemp.protected = iniElement.protected;
      
      let keys = Object.keys(iniElementTemp.values);
      this.iniToEditGroups = [...this.iniToEditGroups, { label: 'Adicionar novo Grupo', value: 'NEWGROUP' }];

      iniElementTemp.values.forEach(element => {
        let value = element['value'];
        let property_name = iniElementTemp.group + '-' + element['key'];
        let objectfield: objectForm = {
          label: element['key'],
          property: property_name,
          required: true,
          minLength: 1,
          gridColumns: 6,
          gridSmColumns: 6,
          disabled: false,
          fieldValue: value,
          value: value
        }
        if ( element['protected'] === false ) this.fieldsToSelect = [...this.fieldsToSelect, {label: property_name, value: property_name}]
        if ( countDivider === 0 ) {
          countDivider = 1;
          objectfield.divider = iniElementTemp.group;
          this.iniToEditGroups = [...this.iniToEditGroups, { label: iniElementTemp.group, value: iniElementTemp.group }];
        }

        if ( element['protected'] === true ) objectfield.disabled = true;

        // this.iniToEditFields = [...this.iniToEditFields, objectfield];
        this.iniToEditFields = [...this.iniToEditFields, objectfield];
        this.iniToEditValues[property_name] = value;
      });
      
    });

    this.INIformVisible = true;
  }

  startNewFields(){

    this.newFieldsINI = [];
    this.newValuesINI = {}
    let firstKey: objectForm = {
      label: 'Chave',
      property: 'key-1',
      required: true,
      minLength: 1,
      gridColumns: 6,
      gridSmColumns: 6,
      disabled: false,
      fieldValue: ''
    }

    let firstValue: objectForm = {
      label: 'Valor',
      property: 'value-1',
      required: true,
      minLength: 1,
      gridColumns: 6,
      gridSmColumns: 6,
      disabled: false,
      fieldValue: ''
    }
    this.newFieldsINI = [ firstKey, firstValue];
  }

  addFieldValueDynamicForm() {
    let length =  Object.keys(this.newValuesINI).length;
    let keyProperty = 'key-' + ((length/2) +1)
    let valueProperty = 'value-' + ((length/2) +1)
    let newKey: objectForm = {
      label: 'Chave',
      property: keyProperty,
      required: true,
      minLength: 1,
      gridColumns: 6,
      gridSmColumns: 6,
      disabled: false,
      fieldValue: ''
    }

    let newValue: objectForm = {
      label: 'Valor',
      property: valueProperty,
      required: true,
      minLength: 1,
      gridColumns: 6,
      gridSmColumns: 6,
      disabled: false,
      fieldValue: ''
    }
    this.newFieldsINI = [... this.newFieldsINI, newKey, newValue];
    
  }

  extractValuesFromNewKeys(){
    let values: Array<any> = [];
    for (const [k, v] of Object.entries(this.newValuesINI)) {
      const type = k.split('-', 1)[0];
      const number = k.split('-', 2)[1];
      if (type === 'key') {
        let value = 'value-' + number;
        values = [...values, {key: this.newValuesINI[k], value: this.newValuesINI[value], protected: false}]
      }
    }
    this.addValueToIni(values);
  }

  addValueToIni(values) {
    if (this.newGroup) {
      //adiciona um grupo novo no this.iniObject
      let newGroup = { group: this._newGroup, keys: values}
      this.iniObject['ini'] = [...this.iniObject['ini'], newGroup]
    } else {
      let existGroup = {};
      existGroup = this.iniObject['ini'].find(obj => obj['group'] === this.groupINI);
      const groupIndex = this.iniObject['ini'].indexOf(existGroup);

      values.forEach(element => {
        this.iniObject['ini'][groupIndex].keys = [...this.iniObject['ini'][groupIndex].keys, element]
      })

    }
    this.resetFormValues();
    
  }

  deleteFieldsFromIni() {
    this.fieldsToDelete.forEach(element => {
      const group = element.split('-', 1)[0];
      const key = element.split('-', 2)[1];
      
      let existGroup = {};
      existGroup = this.iniObject['ini'].find(obj => obj['group'] === group);
      const groupIndex = this.iniObject['ini'].indexOf(existGroup);
      
      let existKey = {};
      existKey = this.iniObject['ini'][groupIndex].keys.find(obj => obj['key'] === key);
      const keyIndex = this.iniObject['ini'][groupIndex].keys.indexOf(existKey);
      this.iniObject['ini'][groupIndex].keys.splice(keyIndex, 1);

      this.resetFormValues();

    });
  }

  onChangeShouldInsert(groupSelected){
    if (groupSelected === 'NEWGROUP') {
      this.newGroup = true;
    } else {
      this.newGroup = false;
    }
  }

  convertValuesToINI() {
    let iniInstanceIndex = {};
    iniInstanceIndex = this.iniObject;
    for (const [k, v] of Object.entries(this.iniToEditValues)) {
      // k Type is string
      // v Type is any
      const splitIndex = k.indexOf('-')
      const group = k.slice(0, splitIndex);
      const key = k.slice(splitIndex+1);
      
      let existGroup = {};
      existGroup = iniInstanceIndex['ini'].find(obj => obj['group'] === group);
      const groupIndex = iniInstanceIndex['ini'].indexOf(existGroup);

      let existKey = {};
      existKey = this.iniObject['ini'][groupIndex].keys.find(obj => obj['key'] === key);
      const keyIndex = this.iniObject['ini'][groupIndex].keys.indexOf(existKey);
      
      if( existGroup && existKey ) {
        const oldValue = iniInstanceIndex['ini'][groupIndex].keys[keyIndex];
        if ( oldValue !== undefined ) {
          if( oldValue.value !== v ) iniInstanceIndex['ini'][groupIndex].keys[keyIndex].value = v;
        } else {
          iniInstanceIndex['ini'][groupIndex].keys = [...iniInstanceIndex['ini'][groupIndex].keys, {key: key, value: v, protected: false}];
        }
      } else {
        if( existGroup ) {
          iniInstanceIndex['ini'][groupIndex].keys = [...iniInstanceIndex['ini'][groupIndex].keys, {key: key, value: v, protected: false}];
        } else {
          let tempkeyValue = {key: key, value: v, protected: false}
          let tempObject: tempIniGroup = {
            group: group,
            keys: [tempkeyValue]
          }
          iniInstanceIndex['ini'] = [...iniInstanceIndex['ini'], tempObject];
        }
        
      }
    }

    this.protheusService.saveINIEdited(this._ini, iniInstanceIndex)
      .subscribe(
        res => {
          this.poNotification.message = "INI salvo e em processo de atualização";
          this.poNotificationService.success(this.poNotification);
        }
      );
  }

}
