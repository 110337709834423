<head>
  <!-- Matomo Tag Manager -->
  <script>
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='http://10.171.80.34:9603/js/container_akjXKwvF.js'; s.parentNode.insertBefore(g,s);
    </script>
    <!-- End Matomo Tag Manager -->
  </head>
  <po-page-default p-title="Protheus">

    <div class="po-row">
  
      <po-button
        class="po-md-2"
        (p-click)="createProtheus()" 
        p-kind="primary"
        p-label="Criar Protheus">
      </po-button>
  
      <po-button
        class="po-md-2"
        (p-click)="getAllProtheus(); resetFilters();"
        p-label="Atualizar" 
        p-icon="po-icon-refresh">
      </po-button>
  
      <po-button
        class="po-md-2"
        (p-click)="toggleFilters();"
        p-label="Filtros" 
        p-icon="po-icon-filter">
      </po-button>
  
    </div>
    <div class="container po-row filterInstancesRow">
      <br>
        <div class="button-container" hidden>
          <po-select
            class="filterInstancesSelect po-md-lg-sm-4"
            name="filterOptionsSelect"
            [(ngModel)]="filterSelected"
            (p-change)="onChangeFilterSelect($event)"
            p-label="Filtro"
            [p-options]="filterOptionsSelect"
            hidden
            >
          </po-select>  
            
          <po-button
            class="filterInstancesInvertButton"
            name="filterInstancesInvertButton"
            (p-click)="invertFilterOrder()"
            [p-icon]="templateButton"
            p-kind="tertiary"
            style="display: none;"
          >
          </po-button> 

          <ng-template #templateButton>
            <span class="po-icon po-icon-sort" style="font-size: 30px;" ></span>
          </ng-template>

          <po-checkbox-group class="filterProtheusChkGroup"
            name="filterInstancesChk"
            p-label="Status"
            (p-change)="filterInstances(filterInstancesModel)"
            [(ngModel)]="filterInstancesModel"
            [p-indeterminate]="true"
            [p-columns]="4"
            [p-options]="[{value: 'active', label: 'Active'}, { value: 'deleted', label: 'Deleted' }, { value: 'expired', label: 'Expired' }, { value: 'failed', label: 'Failed' }]"
            hidden
            >
          </po-checkbox-group>
        </div> 
    </div>
  
    <po-divider></po-divider>
    <po-list-view
      p-hide-select-all
      [p-actions]="eventsObject"
      [p-items]="protheusFiltered"
      p-property-title="name">
  
      <ng-template p-list-view-content-template let-item >
        <div class="po-row">
            <po-info p-label="ID" class="po-md-3" [p-value]="item.id"></po-info>
            <po-tag p-label="Status" class="po-md-3" [p-orientation]="vertical" [p-type]="item.statusType" [p-value]="item.deployStatsDataResume"></po-tag>
            <po-info p-label="Release" class="po-md-2" [p-value]="item.releaseLocResume" ></po-info>
            <po-info p-label="Expiração (dias)" class="po-md-2" [p-value]="item.remainingTime" ></po-info>
            <br>
            <po-info p-label="Proprietário" class="po-md-3" [p-value]="item.username" ></po-info>
            <po-info p-label="Usuários compartilhados" class="po-md-5" [p-value]="item.shared" ></po-info>
            <po-info *ngIf="item.active === true" p-label="Criado em: " class="po-md-3" [p-value]="item.createdAt" ></po-info>
            <po-info *ngIf="item.active === false" p-label="Deletado em: " class="po-md-3" [p-value]="item.updatedAt" ></po-info>
        </div>
  
        <po-divider></po-divider>
        
        <div class="po-row" *ngFor="let artifact of item.artifacts; let i = index">
          <po-info [p-label]="artifact.kind" class="po-md-2" p-orientation="horizontal" [p-value]="artifact.name" ></po-info>
          <po-info p-label="IP" class="po-md-2" p-orientation="horizontal" [p-value]="artifact.ip" ></po-info>
          <po-info p-label="Porta" class="po-md-2" p-orientation="horizontal" [p-value]="artifact.port" ></po-info>
          <po-info *ngIf="artifact.url" p-label="Acessar" class="po-md-4" p-orientation="horizontal" [p-url]="artifact.url" [p-value]="artifact.url" ></po-info>
        </div>
  
      </ng-template>
    </po-list-view>  
  
    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>
    
  </po-page-default>
  
  <po-modal #createModal 
      p-title="Nova Instância Protheus"
      [p-primary-action]="createProtheusExecute">
  
      <!-- <app-create-resource-form
        hasWarning="false"
        [form_fields]="createProtheusFields"
        (form_values)="createProtheusValues"
        [form_validate]="createProtheusValidation"
        [validate_fields] = "createProtheusValidationFields">

      </app-create-resource-form> -->

      <div class="sample-container" *ngIf="loading">
        <po-loading-overlay></po-loading-overlay>
      </div>
  
      <p class="infoLabel" style="font-weight: bold;">ATENÇÃO!</p>
      <p class="infoLabel">Todas as novas instancias são criadas com apenas 7 dias de duração, podendo ser prorrogadas como antes.</p>    
  
      <div class="po-row">
        <po-input
          class="po-md-12"
          name="Nome"
          [(ngModel)]="_name"
          p-label="Nome">
        </po-input>
      </div>
  
      <div class="po-row">
        <po-select
          class="po-lg-6"
          name="releaseCreate"
          [(ngModel)]="_release"
          p-label="Release"
          [p-required]="true"
          (p-change)="onChangeRelease($event)"
          [p-options]="releasesSelect">
        </po-select>
  
        <po-select
          class="po-lg-6"
          name="idiomasCreate"
          [(ngModel)]="_idioma"
          p-label="Idioma"
          [p-required]="true"
          [p-disabled]="disableLocalizacao"
          [p-options]="idiomas">
        </po-select>
      </div>
  
      <po-divider></po-divider>
  
      <div class="po-row">
        <po-select
          class="po-lg-6"
          name="localizacaoCreate"
          [(ngModel)]="_localizacao"
          p-label="Localização"
          [p-required]="true"
          [p-disabled]="disableLocalizacao"
          (p-change)="onChangeLocalizacao($event)"
          [p-options]="localizacoesSelect">
        </po-select>
  
      </div>
  
      <div class="po-row">
        <po-select
          class="po-lg-6"
          name="binariocreate"
          [(ngModel)]="_appserver"
          p-label="Binário"
          [p-required]="true"
          [p-disabled]="disableLocalizacao"
          (p-change)="onChangeBinary($event)"
          [p-options]="binariosSelect">
        </po-select>
  
        <po-switch
          name="restcreate"
          class="po-lg-3"
          [(ngModel)]="_rest"
          p-label="Habilitar porta REST?"
          p-label-off="Não"
          p-label-on="Sim!"
          [p-label-position]="labelPosition">
        </po-switch>

        <po-switch
          name="mppcreate"
          class="po-lg-3"
          [(ngModel)]="_mpp"
          p-label="Habilitar MultiProtocol Port?"
          p-label-off="Não"
          p-label-on="Sim!"
          [p-label-position]="labelPosition">
        </po-switch>
      </div>
  
      <po-divider></po-divider>
  
      <div class="po-row">
        <po-select
          class="po-lg-6"
          name="dbaccesscreate"
          [(ngModel)]="_dbaccess"
          p-label="DbAccess"
          [p-required]="true"
          [p-disabled]="disableLocalizacao"
          (p-change)="onChangeDbaccess($event)"
          [p-options]="dbaccessSelect">
        </po-select>
  
        <po-select
          class="po-lg-6"
          name="databaseCreate"
          [(ngModel)]="_sgdbType"
          p-label="Database"
          [p-required]="true"
          (p-change)="onChangeBase($event)"
          [p-disabled]="disableRPO"
          [p-options]="baseSelect">
        </po-select>
  
      </div>
  
      <po-divider></po-divider>
  
      <div class="po-row">
        <po-select
          class="po-lg-6"
          name="rpocreate"
          [(ngModel)]="_rpo"
          p-label="RPO"
          [p-required]="true"
          [p-disabled]="disableRPO"
          [p-options]="rposSelect">
        </po-select>
      </div>
    
  </po-modal>
  
  <po-modal #detailsModal 
      [p-title]="_name">
  
      <div class="sample-container" *ngIf="loading">
        <po-loading-overlay></po-loading-overlay>
      </div>

      <app-details
        [protheusSelected]="_protheusSelected">
      </app-details>
      
    
  </po-modal>
  
  <po-modal #updateModal 
      p-title="Renomear/Prorrogar Protheus"
      [p-primary-action]="updateProtheusExecute">
  
      <div class="sample-container" *ngIf="loading">
        <po-loading-overlay></po-loading-overlay>
      </div>
  
      <app-rename-extend 
        [name]="_name"
        [expirationDays]="_expirationDays"
        [expiraOptions]="expiraOptions"
        (saveData)="getUpdateData($event)"></app-rename-extend>
    
  </po-modal>

<po-modal #shareModal
  p-title="Compartilhar Protheus"
  [p-primary-action]="okShare"
  [p-secondary-action]="cancelShare">

  <div class="sample-container" *ngIf="loading">
    <po-loading-overlay></po-loading-overlay>
  </div>

  <app-share-resource
    [usersSelected]="usersSelected"
    [usersInArea]="usersInArea"
    (saveUserShared)="getUpdateSharedData($event)">

  </app-share-resource>
</po-modal>
    
<po-modal #deleteModal 
    p-title="Deletar Protheus"
    [p-primary-action]="deleteProtheusExecute">

    <div class="po-row">
      <po-select
        class="po-lg-6"
        name="deleteprotheus"
        [(ngModel)]="deleteApprove"
        p-label="Deletar instância?"
        [p-required]="true"
        [p-options]="[{label: 'SIM', value: 'SIM' },{label: 'NÃO', value: 'NÃO' }]"
      >
      </po-select>
    </div>
  
</po-modal>

<po-modal #editINIModal 
  p-title="Edição de Ini"
  [p-primary-action]="editINIProtheusExecute"
  [p-secondary-action]="editINIProtheusCancel"
  p-hide-close="true">

  <div class="sample-container" *ngIf="loading">
    <po-loading-overlay></po-loading-overlay>
  </div>

  <div class="po-row">

    <po-combo
      class="po-lg-6"
      name="objetosIniProtheus"
      p-label="Objetos - ProtheusINI"
      [p-options]="objetosIni"
      [(ngModel)]="objetoSelecionado"
      (p-change)="onChangeobjetosIni($event)">
    </po-combo>

  </div>

  <div class="po-row" >
    <po-dynamic-form
      #dynamicForm
      [p-fields]="iniToEditFields"
      [p-value]="iniToEditValues"
    >
    </po-dynamic-form>
  </div> 

  <div class="po-row" *ngIf="INIformVisible">
    <po-button
      class="po-md-3"
      (p-click)="startNewFields(); pageSlideInsert.open();"
      p-kind="primary"
      p-label="Adicionar chave" 
      p-icon="po-icon-plus">
    </po-button>

    <po-button
      class="po-md-3"
      (p-click)="pageSlideDelete.open()"
      p-label="Remover chaves" 
      p-icon="po-icon-minus">
    </po-button>

    <po-button
      class="po-md-3"
      (p-click)="resetFormValuesFromIni()" 
      [p-danger]="true"
      p-label="Resetar valores" >
    </po-button>

  </div>

  <po-page-slide p-title="Configuration" p-size="sm" #pageSlideInsert>

    <div class="po-row">

      <po-select
        class="po-lg-6"
        name="groupINI"
        [(ngModel)]="groupINI"
        p-label="Grupo"
        [p-required]="true"
        [p-options]="iniToEditGroups"
        (p-change)="onChangeShouldInsert($event)"
      >
      </po-select>

      <po-input *ngIf="newGroup"
          class="po-md-12"
          name="newGroup"
          [(ngModel)]="_newGroup"
          p-label="Novo Grupo">
        </po-input>
    </div>

    <div class="po-row" >

      <po-dynamic-form
        #dynamicForm
        [p-fields]="newFieldsINI"
        [p-value]="newValuesINI"
      >
      </po-dynamic-form>

    </div>

    <div class="po-row">
      <po-button 
        class="po-md-3"
        (p-click)="addFieldValueDynamicForm()"
        p-label="Adicionar chave" 
        p-icon="po-icon-plus">
      </po-button>

    </div>

    <br>
    <br>
    <br>

    <div class="po-row">
      <po-button 
        class="po-md-6"
        (p-click)="extractValuesFromNewKeys(); pageSlideInsert.close()"
        p-label="Salvar campos" 
        p-icon="po-icon-plus">
      </po-button>

    </div>
  </po-page-slide>

  <po-page-slide p-title="Configuration" p-size="sm" #pageSlideDelete>

    <div class="po-row" >
      <po-multiselect
        name="fieldSelect"
        class="po-md-12"
        [(ngModel)]="fieldsToDelete"
        label="Campos"
        [p-options]="fieldsToSelect"
        p-height="400px"
        p-required>
      </po-multiselect>
    </div>

    <div class="po-row">

      <po-button 
        class="po-md-6"
        (p-click)="deleteFieldsFromIni(); pageSlideDelete.close()"
        p-label="Deletar campos" 
        p-icon="po-icon-minus">
      </po-button>
    </div>
  </po-page-slide>

</po-modal>
