import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MATOMO_CONFIGURATION, MatomoConfiguration, MatomoTracker } from '@ngx-matomo/tracker';
import { PoMenuItem, PoModalAction, PoModalComponent, PoNotificationService, PoToolbarAction } from '@po-ui/ng-components';
import { MainService } from '../app.service';
import { Areas } from '../classes/Areas';
import { Token } from '../classes/Token';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @ViewChild('cadastraArea', {static: false}) selectAreaModal: PoModalComponent;
  @ViewChild('getPATAToken', {static: false}) getPATAToken: PoModalComponent;
  @ViewChild('getCompileKey', {static: false}) getCompileKey: PoModalComponent;

  areas: any[] = [];
  areaSelected: string = '';
  saveArea: PoModalAction = {
    action: () => {
      this.saveUserArea();
      this.selectAreaModal.close();
    },
    label: 'Salvar'
  };
  actualArea: string = this.mainservice.userToken.area;

  // PATA TOKEN
  tokenExists: boolean = false;
  tokenColumns: Array<any> = [];
  tokens: Array<any> = [];
  tokenUser: string = '';
  tokenData: string = '';
  tokenDate: string = '';
  tUsername: string = '';
  tPassword: string = '';
  patatoken: string = '';
  compileKey = {
    "payload": {
      "iss": "",
      "sub": "",
      "auth": "",
      "exp": ""
    },
    "token": ""
  };

  profileActions: Array<PoToolbarAction> = [
    { icon: 'po-icon-settings', label: 'Trocar informação de área de trabalho', type: 'default', separator: true, action: item => this.selectAreaModal.open()},
    // { icon: 'po-icon-settings', label: 'Gerar token permanente - VS Code', type: 'default', separator: true, action: item => this.openPATAToken()},
    { icon: 'po-icon-settings', label: 'Gerar token de compilação', type: 'default', separator: true, action: item => this.openCompileKey()},
    { icon: 'po-icon-exit', label: 'Sair', type: 'danger', separator: true, action: item => this.mainservice.logout() }
  ];

  menu: Array<PoMenuItem> = [
    { label: 'Databases', icon: 'po-icon-database', shortLabel: 'Database', link: "database" },
    { label: 'Protheus', icon: 'po-icon-device-notebook', shortLabel: 'Protheus', link: "protheus" },
    { label: 'Query Analyzer', icon: 'po-icon-exam', shortLabel: 'Query Analyzer', subItems: [
      { label: 'Analyzer', icon: 'po-icon-exam', shortLabel: 'Analyzer', link: "query-analyzer" },
      { label: 'Pontos de Atenção', icon: 'po-icon-warning', shortLabel: 'Pontos de Atenção', link: "test-manager" },
    ] },
    { label: 'JamesBond', icon: 'po-icon-users', shortLabel: 'JamesBond', link: "jamesbond" },
    { label: 'Totvs News Preview', icon: 'po-icon-device-desktop', shortLabel: 'Totvs News Preview', link: "totvs-news-preview" },
    { label: 'Dúvidas & Suporte', icon: 'po-icon-help', shortLabel: 'Dúvidas & Suporte', link: "support" }
  ];

  constructor(
    private router: Router,
    public mainservice: MainService,
    public poNotification: PoNotificationService,
    public cd: ChangeDetectorRef,
    private readonly tracker: MatomoTracker,
    @Inject(MATOMO_CONFIGURATION) private readonly config: MatomoConfiguration) { 
      config.siteId === '1',
      config.trackerUrl === '//10.171.80.34:9603/';
    }

  ngOnInit(): void {
    this.tracker.trackPageView('Menu');

    this.getAreas();

    if(this.mainservice.userToken.role === 'superuser') {
      this.menu = [
        { label: 'Databases', icon: 'po-icon-database', shortLabel: 'Database', link: "database" },
        { label: 'Protheus', icon: 'po-icon-device-notebook', shortLabel: 'Protheus', link: "protheus" },
        { label: 'Query Analyzer', icon: 'po-icon-exam', shortLabel: 'Query Analyzer', subItems: [
          { label: 'Analyzer', icon: 'po-icon-exam', shortLabel: 'Analyzer', link: "query-analyzer" },
          { label: 'Pontos de Atenção', icon: 'po-icon-warning', shortLabel: 'Pontos de Atenção', link: "test-manager" },
        ] },
        { label: 'JamesBond', icon: 'po-icon-users', shortLabel: 'JamesBond', link: "jamesbond" },
        { label: 'Totvs News Preview', icon: 'po-icon-device-desktop', shortLabel: 'Totvs News Preview', link: "totvs-news-preview" },
        { label: 'Admin', icon: 'po-icon-settings', shortLabel: 'Administration', subItems: [
          { label: 'Painel Geral', icon: 'po-icon-chart-area', shortLabel: 'Painel Geral', link: "adminpanel" },
          { label: 'Protheus - Admin', icon: 'po-icon-world', shortLabel: 'Protheus - Admin', link: "protheusAdmin" },
          { label: 'JamesBond - Admin', icon: 'po-icon-pallet-partial', shortLabel: 'JamesBond - Admin', link: "jamesbAdmin" }
        ]},
        { label: 'Dúvidas & Suporte', icon: 'po-icon-help', shortLabel: 'Dúvidas & Suporte', link: "support" }
      ];
    }
  }

  ngAfterViewInit() {
    if (this.mainservice.userToken.area === '') {
      this.poNotification.warning("Você deve selecionar uma área de trabalho para continuar o acesso!");
      this.openSelectArea();
    } else {
      this.router.navigate(['menu/home']);
    }
    this.cd.detectChanges();
  }

  openSelectArea() {
    this.selectAreaModal.open();
  }

  getAreas() {
    this.mainservice.workAreas = [];
    this.areas = [];

    this.mainservice.getAreas()
      .subscribe(res => {
        const retorno: any = res;
          retorno.forEach(element => {
            const areasTemp = new Areas();

            areasTemp.id = element.id;
            areasTemp.area = element.area;
            areasTemp.restricted = element.restricted;
            this.mainservice.workAreas.push(areasTemp);
            this.areas = [...this.areas, { value: areasTemp.area, label: areasTemp.area }];
          });
      });
  }

  saveUserArea() {
    this.mainservice.changeArea(this.mainservice.userToken.username, this.areaSelected)
      .subscribe(res => {
        this.selectAreaModal.close();
        
      });
  }

  openPATAToken() {
    this.tokens = [];
    this.tokenExists = false;
    this.tokenUser = '';
    this.tokenData = '';
    this.tokenDate = '';

    this.mainservice.getPATAToken()
      .subscribe(res => {
        const retorno: any = res;
          retorno.forEach(element => {
            const tokenTemp = new Token();
            tokenTemp.id = element._id;
            tokenTemp.username = element.username;
            tokenTemp.token = element.token;
            tokenTemp.createdAt = element.createdAt;
            this.tokens.push(tokenTemp);
            this.tokenExists = true;
            this.tokenUser = element.username;
            this.tokenData = element.token;
            this.tokenDate = element.createdAt;
          });
      });
    this.getPATAToken.open();
  }

  openCompileKey() {
    this.compileKey = {
      "payload": {
        "iss": "",
        "sub": "",
        "auth": "",
        "exp": ""
      },
      "token": ""
    };
    
    this.mainservice.getCompileToken().subscribe(data => {
      this.compileKey = data;
      
    });

    this.getCompileKey.open();
  }

  generatePATAToken() {
    if ( this.tUsername !== '' && this.tPassword !== '' ) {
      this.mainservice.createPATAToken(this.tUsername, this.tPassword )
      .subscribe(res => {
        this.tokenData = res;
      });
    }
  }

  deletePATAToken() {
    this.mainservice.deletePATAToken(this.tokens[0].id)
      .subscribe(res => {
        this.openPATAToken();
      });
  }

}
