import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PoModule } from '@po-ui/ng-components';

@Component({
  selector: 'app-rename-extend',
  templateUrl: './rename-extend.component.html',
  styleUrls: ['./rename-extend.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    PoModule
  ]
})
export class RenameExtendComponent {

  @Input() name: string = '';
  @Input() expirationDays: number = 7;
  @Input() expiraOptions: Array<any> = [  ];
  @Output() saveData = new EventEmitter<{ _name: string, _expirationDays: number }>();

  onChange() {
    console.log(this.name, this.expirationDays)
    this.saveData.emit({
      _name: this.name,
      _expirationDays: this.expirationDays
    });
  }

}
